import { FileExcelOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import ReactExport from 'react-export-excel-xlsx-fix';
import { estadoSpanish, estadoTrsSpanish } from "./GenerarPdf";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ExportExcel.ExcelColumn

const DataExportExcelOrden = (ventas, fechaInicio, fechaFin, estado) => {
  return[
    {
      columns: [],
      data: [
        //INICIO DE FILA
        [
          //INICIO DE CELDA 
          {
            value:`Ordenes de Pago Desde:`,
            style:{font:{bold:true}, width: {wpx: 120}}
          },
          //FIN DE CELDA 
          moment(fechaInicio).format("DD/MM/YYYY"),
          {},
          {
            value:`Hasta:`,
            style:{font:{bold:true}}
          },
          moment(fechaFin).format("DD/MM/YYYY"),
          
        ]
        //FIN DE FILA
      ]
    },
    {
      ySteps: -1,
      columns: [],
      data: [
        [
          {
            value:`Estado:`,
            style:{font:{bold:true}}
          },
          estadoSpanish(estado)
        ]
      ]
    },
    {
      ySteps: 1,
      columns:
        [
          {
            value: "CODIGO",
            widthPx: 100
          }, 
          {
            value:"MONTO"
          },
          {
            value:"ESTADO"
          },
          {
            value:"PACIENTE",
            widthPx: 250
          },
          {
            value:"F. SOLICITUD",
            widthPx: 150
          }
        ],
      data: ventas.map(venta => {
        return[
          {value: `${venta.codigo}`},
          {value: `S/.${venta.monto}`},
          {value: `${estadoSpanish(venta.estado)}`},
          {value: `${venta.ap_nom}`},
          {value: `${moment.utc(venta.fecha_venta_web).format("DD/MM/YYYY HH:mm")}`}
        ]
      })
    }
  ]
}


const DataExportExcelTrs = (transacciones, fechaInicio, fechaFin) => {
  return[
    {
      columns: [],
      data: [
        //INICIO DE FILA
        [
          //INICIO DE CELDA 
          {
            value:`Transacciones Desde:`,
            style:{font:{bold:true}, width: {wpx: 80}}
          },
          //FIN DE CELDA 
          moment(fechaInicio).format("DD/MM/YYYY"),
          {},
          {
            value:`Hasta:`,
            style:{font:{bold:true}}
          },
          moment(fechaFin).format("DD/MM/YYYY"),
          
        ]
        //FIN DE FILA
      ]
    },
    {
      ySteps: 1,
      columns:
        [
          {
            value: "SERVICIO",
            widthPx: 200
          }, 
          {
            value: "CODIGO",
            widthPx: 100
          }, 
          {
            value:"MONTO"
          },
          {
            value:"PACIENTE",
            widthPx: 250
          },
          {
            value:"ESTADO"
          },
          {
            value:"F. TRANSACCION",
            widthPx: 100
          },
          {
            value: "ORIGEN",
            widthPx: 100
          }
        ],
      data: transacciones.map(transaccion => {
        return[
          {value: `${transaccion.tipo_atencion}`},
          {value: `${transaccion.codigo}`},
          {value: `S/.${transaccion.monto}`},
          {value: `${transaccion.ap_nom}`},
          {value: `${estadoTrsSpanish(transaccion.estado)}`},
          {value: `${moment.utc(transaccion.fecha_transaccion).format("DD/MM/YYYY HH:mm")}`},
          {value: `${transaccion.origen}`},
        ]
      })
    }
  ]
}

export const ExcelFileOrdenPago = (VentasListFiltradas, fechaInicio, fechaFin, estado) => {
  return(
    <>
      <ExcelFile element={
        <Button className='bg-color-success' title="Exportar PDF" 
        // onClick={e => ExportarExcel()}
        icon={<FileExcelOutlined />}>EXCEL
        </Button>} filename="Ordenes de Pago">
        <ExcelSheet dataSet={DataExportExcelOrden(VentasListFiltradas, fechaInicio, fechaFin, estado)} 
          name="Ordenes de Pago"/>
      </ExcelFile>
    </>
  )
}

export const ExcelFileTrs = (TransaccionesFiltradas, fechaInicio, fechaFin, estado) => {
  return(
    <>
      <ExcelFile element={
        <Button className='bg-color-success' title="Exportar PDF" 
        // onClick={e => ExportarExcel()}
        icon={<FileExcelOutlined />}>EXCEL
        </Button>} filename="Transacciones">
        <ExcelSheet dataSet={DataExportExcelTrs(TransaccionesFiltradas, fechaInicio, fechaFin, estado)} 
          name="Transacciones"/>
      </ExcelFile>
    </>
  )
}