import moment from "moment";
import { Button, Modal, Table, Tag } from 'antd';
import { CloseOutlined } from "@ant-design/icons";

const ModalTrsPorOrden = (props) => {
  
  const columns = [
    {
      title: 'ESTADO SOLICITUD',
      dataIndex: '',
      key: '',
      render: record => {
        switch (record.estado) {
          case "PAID":
            switch (record.detalle_estado) {
              case "ACCEPTED":
                return(<Tag color={'#b7ce63'}>PAGADO - ACEPTADO</Tag>);
              
              case "AUTHORISED":
                return(<Tag color={'#b7ce63'}>PAGADO - AUTORIZADO</Tag>);
            
              case "CAPTURED":
                return(<Tag color={'#b7ce63'}>PAGADO - CAPTURADO</Tag>);
              
              case "PARTIALLY_AUTHORISED":
                return(<Tag color={'#b7ce63'}>PAGADO - PARCIALMENTE AUTORIZADO</Tag>);
            
              default:
              break;
            }
          break;

          case "RUNNING":
            switch (record.detalle_estado) {
              case "AUTHORISED_TO_VALIDATE":
                return(<Tag color={'#336cfb'}>EN CURSO - AUTORIZADO PARA VALIDAR</Tag>);

              case "WAITING_AUTHORISATION":
                return(<Tag color={'#336cfb'}>EN CURSO - ESPERANDO AUTORIZACIÓN</Tag>);
                
              case "WAITING_AUTHORISATION_TO_VALIDATE":
                return(<Tag color={'#336cfb'}>EN CURSO - ESPERANDO AUTORIZADO PARA VALIDAR</Tag>);
                  
              default:
              break;
            }
          break;  

          case "UNPAID":
            switch (record.detalle_estado) {
              case "REFUSED":
                return(<Tag color={'#ed5564'}>NO PAGADO - RECHAZADO</Tag>);
              
              case "ERROR":
                return(<Tag color={'#ed5564'}>NO PAGADO - {record.detalle_estado}</Tag>);
                  
              case "CANCELLED":
                return(<Tag color={'#ed5564'}>NO PAGADO - CANCELADO</Tag>);
                  
              case "EXPIRED":
                return(<Tag color={'#ed5564'}>NO PAGADO - EXPIRADO</Tag>);

              default:
                break;
            }
          break;

          default:
          break;
        }
      }
    },
    {
      title: 'MONTO',
      dataIndex: 'monto',
      key: 'monto',
      render: text => (
        "S/."+text
      )
    },
    {
      title: 'F. TRANSACCIÓN',
      dataIndex: 'fecha_transaccion',
      key: 'fecha_transaccion',
      render: text => (
        moment.utc(text).format('DD/MM/YY HH:mm')
      )
    },
    {
      title: 'F. CAPTURA',
      dataIndex: 'fecha_captura',
      key: 'fecha_captura',
      render: text => (
        moment.utc(text).format('DD/MM/YY')
      )
    },
  ];

  if (props.transaccionList.length === undefined) {
    return(
      <>
        <Modal
          visible={props.openModal}
          closable={false}
          title={<h3 className='m-0'>Solicitud de Pago: {props.venta.codigo}</h3>}
          onCancel={props.handleCloseModal}
          footer={
            <div className='modal-footer d-flex justify-content-between'>
              <Button danger key='back' onClick={props.handleCloseModal} icon={<CloseOutlined />}>
                Cerrar
              </Button>
            </div>
          }
        >
          <h3>No Se Encontraron Transacciones</h3>
        </Modal>
      </>
    )  
  } else {
    return(
      <>
        <Modal
          visible={props.openModal}
          closable={false}
          title={<h3 className='m-0'>Solicitud de Pago: {props.venta.codigo}</h3>}
          onCancel={props.handleCloseModal}
          footer={
            <div className='modal-footer d-flex justify-content-between'>
              <Button danger key='back' onClick={props.handleCloseModal} icon={<CloseOutlined />}>
                Cerrar
              </Button>
            </div>
          }
          width='50%'
        >
          <Table
            pagination = {{ pageSize: 5 }}
            columns = {columns}
            dataSource = {props.transaccionList}
            size='small'
          />
        </Modal>
      </>
    )  
  }
  
}

export default ModalTrsPorOrden