import React, { useEffect } from 'react';

import { Button, Form, Input, message } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';

import PublicLayout from '../../layout/public/Public';
import { useHistory } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { useNavigateHome } from '../../utils/use-navigate-home';
import Cookies from "universal-cookie";

const cookies = new Cookies()

const { Item } = Form;

const SignIn = () => {
  const [form] = useForm();
  const navigateHome = useNavigateHome();
  const history = useHistory();

  useEffect(() => {
    if (cookies.get('nameid')) {
      // window.location.href='/'
      history.push(`/`);
    }
  }, []);

  const login = () => {
    form
      .validateFields()
      .then(async () => {
        /**TRAER login Y password */
        //console.log(form.getFieldsValue())
        const usuario = form.getFieldsValue()
        // console.log(usuario.login, usuario.password)
        const body = {
          Email: usuario.login,
          Password: usuario.password
        }

        try {
          await fetch(`${process.env.REACT_APP_HOSTNAME}/getLogin`,{
            method: "POST",
            body: JSON.stringify(body),
            headers:{
              'Content-Type': 'application/json'
            }
          }).then(res => res.json())
          .then(data => {

            if (data === 'Acceso denegado' || data === '') {
              message.error('ERROR: Usuario y/o Contraseña Incorrecta') 
            } else {
              /**EL TOKEN DEVUELVE:  ALGO.ALGO1.ALGO2, SE NECESITA EL ALGO1 */
              const substring1 = data.accessToken.substring(data.accessToken.indexOf('.')+1)
              const substring2 = substring1.substring(0, substring1.indexOf('.'))
              let loginResponse = Buffer.from(substring2, "base64").toString();

              const loginJson = JSON.parse(loginResponse)
              //PATH PARA Q SEAN ACCESIBLES DESDE CUALQUIER PAG
              cookies.set('nameid', loginJson.nameid, {path: '/'})
              cookies.set('unique_name', loginJson.unique_name, {path: '/'})
              cookies.set('role', loginJson.role, {path: '/'})

              message.success(`Bienvenido ${loginJson.unique_name}`)
              navigateHome()
            }

          }).catch(err => console.log(err))

        } catch (error) {
          console.log(error)
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <PublicLayout bgImg={`${window.origin}/content/login-page.jpg`}>
      <h4 className='mt-0 mb-1'>Login</h4>

      <p className='text-color-200'>Ingresar</p>

      <Form form={form} layout='vertical' className='mb-4'>
        <Item name='login' rules={[{ required: true, message: <>Ingrese Usuario</> }]}>
          <Input placeholder='Usuario' />
        </Item>
        <Item name='password' rules={[{ required: true, message: <>Ingrese Contraseña</> }]}>
          <Input placeholder='Contraseña' type='password' />
        </Item>

        <Button
          block={false}
          type='primary'
          onClick={login}
          htmlType='submit'
          icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />}
        >
          Login
        </Button>
      </Form>
      <br />

    </PublicLayout>
  );
};

export default SignIn;
