import moment from "moment";
import { Button, Modal, Input, Popconfirm } from 'antd';
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";

const ModalFechaSolicitud = (props) => {

  return (
    <>
      <Modal
        visible={props.openModal}
        closable={false}
        title={<h3 className='m-0'>Solicitud de Pago: {props.venta.codigo}</h3>}
        onCancel={props.handleCloseModal}
        footer={
          <div className='modal-footer d-flex justify-content-between'>
            <Button danger key='back' onClick={props.handleCloseModal} icon={<CloseOutlined />}>
              Cerrar
            </Button>

            <Popconfirm
              title = "¿Cambiar Fecha de Vigencia? (Le llegará un correo al Paciente)"
              onConfirm={props.Editar} okText="Si" cancelText="No"
            >
              <Button className='bg-color-success' loading = {props.loadingButton === true?true:false}
                type='primary' icon={<SaveOutlined />}>
                Guardar
              </Button>
            </Popconfirm>
            
          </div>
        }
      >
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='elem-list'>
              <h4>Fecha de Venc.:</h4> 
            </div>
          </div> 

          <div className='col-md-6 col-sm-12'>
            <div className='elem-list'>
              <Input type=/*"date"*/"datetime-local"
                value={moment(props.venta.fecha_vencimiento).add(5, 'h').format("YYYY-MM-DDTHH:mm")} 
                onChange={e => props.setVenta({...props.venta, fecha_vencimiento:e.currentTarget.value})}
                // onChange={e => console.log(e.currentTarget.value)}
              />  
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalFechaSolicitud