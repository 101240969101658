import { IRoute } from '../interfaces/routing';

import SettingsPage from '../pages/settings/Settings';

import SolicitudVenta from '../pages/components/SolicitudVenta';
import Transacciones from '../pages/components/Transacciones';
import FormularioPago from '../pages/components/FormularioPago';

export const defaultRoutes: IRoute[] = [
  {
    path: 'settings',
    component: SettingsPage
  },
  {
    path: 'solicitudesPago',
    component: SolicitudVenta
  },
  {
    path: 'transacciones',
    component: Transacciones
  }
];
