
import { useState, useEffect } from "react";

import './Actions.scss';

import Notifications from './Notifications';
import SettingsDropdown from './SettingsDropdown';
import Cookies from "universal-cookie";

const cookies = new Cookies()

const Actions = () => {
  const [unique_name, setUnique_name] = useState('')

  useEffect(() => {
    setUnique_name(cookies.get('unique_name')) 
  }, [])

  return(
    <div className='actions'>
      {/* <Notifications /> */}
      <b>{unique_name}</b>
      <SettingsDropdown />
    </div>  
  )
  
};

export default Actions;
