import moment from "moment";
import React from "react";
import {PDFExport} from '@progress/kendo-react-pdf'
import { Table } from "antd";

const columnasPDFOrden = (estado) => {
  let columnsOrdenPago = [
    {
      title: 'CODIGO',
      dataIndex: 'codigo',
      key: 'codigo'
    },
    {
      title: 'MONTO',
      dataIndex: 'monto',
      key: 'monto',
      render: text => (
        "S/."+text
      )
    }
  ]

  if (estado === undefined) {
    columnsOrdenPago = [...columnsOrdenPago,
      {
        title: 'ESTADO',
        dataIndex: 'estado',
        key: 'estado',
        render: text => (
          estadoSpanish(text)
        )
      },
      {
        title: 'PACIENTE',
        dataIndex: 'ap_nom',
        key: 'ap_nom'
      },
      {
        title: 'F. SOLICITUD',
        dataIndex: 'fecha_venta_web',
        key: 'fecha_venta_web',
        render: text => (
          moment.utc(text).format('DD/MM/YY HH:mm')
        )
      }
    ]
  } else {
    columnsOrdenPago = [...columnsOrdenPago,
      {
        title: 'PACIENTE',
        dataIndex: 'ap_nom',
        key: 'ap_nom'
      },
      {
        title: 'F. SOLICITUD',
        dataIndex: 'fecha_venta_web',
        key: 'fecha_venta_web',
        render: text => (
          moment.utc(text).format('DD/MM/YY HH:mm')
        )
      }
    ]
  }

  return columnsOrdenPago
}


const columnsTrs = [
  {
    title: 'CODIGO',
    dataIndex: 'codigo',
    key: 'codigo'
  },
  {
    title: 'MONTO',
    dataIndex: 'monto',
    key: 'monto',
    render: text => (
      "S/."+text
    )
  },
  {
    title: 'PACIENTE',
    dataIndex: 'ap_nom',
    key: 'ap_nom'
  },
  {
    title: 'ESTADO',
    dataIndex: 'estado',
    key: 'estado',
    render: text => (
      estadoTrsSpanish(text)
    )
  },
  {
    title: 'F. TRANSACCION',
    dataIndex: 'fecha_transaccion',
    key: 'fecha_transaccion',
    render: text => (
      moment.utc(text).format('DD/MM/YY HH:mm')
    )
  }
]

const PageTemplate = (props) => {
  return (
    <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
      Pag. {props.pageNum} de {props.totalPages}
    </div>
  );
};

export const estadoSpanish = estado => {
  switch (estado) {
    case undefined:
      return 'TODOS'

    case 'RUNNING':
      return 'EN CURSO'

    case 'PAID':
      return 'PAGADO'

    case 'CANCELED':
      return 'CANCELADO'

    case 'REFUSED':
      return 'RECHAZADO'

    case 'EXPIRED':
      return 'EXPIRADO'

    default:
      break;
  }
}

export const estadoTrsSpanish = estado => {
  switch (estado) {
    case undefined:
      return 'TODOS'

    case 'RUNNING':
      return 'EN CURSO'

    case 'PAID':
      return 'PAGADO'

    case 'UNPAID':
      return 'NO PAGADO'

    default:
      break;
  }
}

export const PdfFileOrdenPago = (componentePDF, VentasListFiltradas, fechaInicio, fechaFin, estado) => {
  return(
    <>
      <div style={{ position: "absolute", left: "-4000px", top: 0 }}>
        <div className='row'>
          <PDFExport ref={componentePDF} paperSize='A4' margin="1cm" landscape={true}
            pageTemplate={PageTemplate} fileName='Ordenes de Pago'
          >
            <div className='modal-footer d-flex justify-content-between'>
              <p>Ordenes de Pago desde: {fechaInicio} hasta: {fechaFin}</p>
              <p>Estado: {estadoSpanish(estado)}</p>  
            </div>
            
            <Table
              pagination={false}
              columns={columnasPDFOrden(estado)}
              dataSource={VentasListFiltradas}
              size='small'
            />
          </PDFExport> 
        </div>
      </div>
    </>
  )
}

export const PdfFileTransacciones = (componentePDF, TransaccionesFiltradas, fechaInicio, fechaFin) => {
  return(
    <>
      <div style={{ position: "absolute", left: "-4000px", top: 0 }}>
        <div className='row'>
          <PDFExport ref={componentePDF} paperSize='A4' margin="1cm" landscape={true}
            pageTemplate={PageTemplate} fileName='Transacciones'
          >
            <div className='modal-footer d-flex justify-content-between'>
              <p>Transacciones desde: {fechaInicio} hasta: {fechaFin}</p>
              {/* <p>Estado: {estadoTrsSpanish(estado)}</p>   */}
            </div>

            <Table
              pagination={false}
              columns={columnsTrs}
              dataSource={TransaccionesFiltradas}
              size='small'
            />
          </PDFExport>
        </div>
      </div>
    </>
  )
}
