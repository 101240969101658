import {Fragment, useEffect, useState, useRef} from "react";
// import Loading from "./Loading";
import moment from "moment";
import { Card, Table, Button, Tag, 
  message, Select, Input } from 'antd';
import { IPageData } from "../../interfaces/page";
import { usePageData } from '../../hooks/usePage';
import { CalendarOutlined, EyeOutlined, FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import ModalFechaSolicitud from "./ModalFechaSolicitud";
import ModalTrsPorOrden from "./ModalTrsPorOrden";
import Cookies from "universal-cookie";
import { useHistory } from 'react-router-dom';
import {PdfFileOrdenPago} from "./GenerarPdf";
import { ExcelFileOrdenPago } from "./GenerarExcel";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Solicitudes de Pago',
  breadcrumbs: [
    // {
    //   title: 'Home',
    //   route: 'solicitudesPago'
    // },
    {
      title: 'Solicitudes de Pago',
    }
  ]
};

const Option = Select.Option;

const SolicitudVenta = () => {
  usePageData(pageData);
  const history = useHistory();
  const componentePDF = useRef(null)

  const [ventasList, setventasList] = useState([])
  const [transaccionList, settransaccionList] = useState([])
  const [detalleVenta, setDetalleVenta] = useState()
  const [loadingButton, setLoadingButton] = useState(false)
  const [ventaSeleccionada, setVentaSeleccionada] = useState({
    id_venta_web: null,
    fecha_vencimiento: null,
    Email: null
  })
  const [jsonAPI, setJsonAPI] = useState({})
  const [openModal, setOpenModal] = useState(false);
  const [openModalTrs, setOpenModalTrs] = useState(false);
  const [selectEstado, setSelectEstado] = useState(undefined)
  const [fechaInicio, setFechaInicio] = useState(moment(new Date).format("YYYY-MM-DD"))
  const [fechaFin, setFechaFin] = useState(moment(new Date).format("YYYY-MM-DD"))

  useEffect(() => {
    
    // console.log(cookies.get('nameid'))
    if (!cookies.get('nameid')) {
      history.push(`/public/sign-in`)
      // window.location.href='/public/sign-in'
    } else {
      getListaVentasWeb()
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getListaVentasWeb()
    }, 10000);
    return () => clearInterval(interval);
  }, [ventasList])

  const getListaVentasWeb = async() => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getVentasWebList`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setventasList(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  /**EDITA LA ORDEN DE LA API */
  const Editar = async() => {
    // console.log(ventaSeleccionada.fecha_vencimiento)
    setLoadingButton(true)
    let body = {...jsonAPI, 
      expirationDate:moment(ventaSeleccionada.fecha_vencimiento).format("YYYY-MM-DDTHH:mm")+":00-05:00"
    }
    
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/PaymentOrder/Update`,{
        method: "POST",
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        // console.log(data)
        /**SI HAY ERROR, NO ACTUALIZA LA BD Y MANDA UN ALERT */
        if (data.answer.errorCode) {
          message.error('ERROR: '+ data.answer.errorCode + ' ' + data.answer.errorMessage)
        } else {
          // console.log(data.answer)
          let bodyVenta = { ...body,
            amount: data.answer.amount/100
          }
          EditarVenta({...bodyVenta,
            id_venta_web:ventaSeleccionada.id_venta_web,
            paymentReceiptEmail: data.answer.paymentReceiptEmail,
            estado: data.answer.paymentOrderStatus,
            paymentURL: data.answer.paymentURL
          })  
        }
        setLoadingButton(false)
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  /**EDITA LA ORDEN DE LA BD */
  const EditarVenta = async(venta) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/updateVentaWeb`,{
        method: "PUT",
        body: JSON.stringify(venta),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        // console.log(data)
        setOpenModal(false)
        message.success("Fecha Actualizada")
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModalTrs = (venta) => {
    setOpenModalTrs(true)
    setVentaSeleccionada(venta)

    getTransaccionesByVentaWeb(venta)
  }

  const getTransaccionesByVentaWeb = async(venta) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getTransaccionesByVentaWeb/${venta.id_venta_web}`)
      .then(res => res.json())
      .then(data => {
        settransaccionList(data)
        // console.log(data)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenModal = (venta) => {
    setOpenModal(true)
    setVentaSeleccionada(venta)
    // console.log(venta)
    let body = {
      orderId: venta.codigo,
      paymentOrderId: venta.paymentOrderId,
      amount: venta.monto*100,
      currency: "PEN",
      customer: {
        email: venta.Email,
        billingDetails: {
          title: venta.sexo,
          firstName: venta.nombres,
          lastName: venta.apellidos,
          country: "PE",
          phoneNumber: venta.Telefono,
          cellPhoneNumber: venta.celular
        }
      },
      channelOptions: {},
      paymentReceiptEmail: "wernher_dalembert@hotmail.com"
    }

    if (venta.Email === '') {
      body = {...body,
        channelOptions: {
          channelType: 'URL',
        }
      }
    } else {
      body = {...body,
        channelOptions: {
          channelType: "MAIL",
          mailOptions: {
            recipient: "wernher_dalembert@hotmail.com"
          }
        },
        paymentReceiptEmail: "wernher_dalembert@hotmail.com"//venta.Email
      }
    }

  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setVentaSeleccionada({
      id_venta_web: null,
      fecha_vencimiento: null,
      Email: null
    })
  }

  const handleCloseModalTrs = () => {
    setOpenModalTrs(false)
    settransaccionList([])
  }

  const columns = [
    {
      title: 'CÓDIGO',
      dataIndex: 'codigo',
      key: 'codigo'
    },
    {
      title: 'OBSERVACIÓN',
      dataIndex: 'observacion',
      key: 'observacion'
    },
    {
      title: 'MONTO',
      dataIndex: 'monto',
      key: 'monto',
      render: text => (
        "S/."+text
      )
    },
    // {
    //   title: 'DNI',
    //   dataIndex: 'docid',
    //   key: 'docid'
    // },
    {
      title: 'PACIENTE',
      dataIndex: 'ap_nom',
      key: 'ap_nom'
    },
    // {
    //   title: 'CORREO',
    //   dataIndex: 'Email',
    //   key: 'Email',
    // },
    {
      title: 'ESTADO SOLICITUD',
      dataIndex: 'estado',
      key: 'estado',
      render: text => {
        switch (text) {
          case "RUNNING":
            // return(<Tag color={'#336cfb'}>{text}</Tag>);
            return(<Tag color={'#336cfb'}>EN CURSO</Tag>);
            
          case "PAID":
            return(<Tag color={'#b7ce63'}>PAGADO</Tag>);
          
          case "CANCELED":
            return(<Tag color={'#ed5564'}>CANCELADO</Tag>);
           
          case "REFUSED":
            return(<Tag color={'#5d5a28 '}>RECHAZADO</Tag>);

          case "EXPIRED":
            return(<Tag>EXPIRADO</Tag>);

          default:
            break;
        }
        
      }
    },
    {
      title: 'F. SOLICITUD',
      dataIndex: 'fecha_venta_web',
      key: 'fecha_venta_web',
      render: text => (
        moment.utc(text).format('DD/MM/YY HH:mm')
      )
    },
    {
      title: 'F. VENC.',
      dataIndex: 'fecha_vencimiento',
      key: 'fecha_vencimiento',
      render: text => (
        moment.utc(text).format('DD/MM/YY HH:mm')
      )
    },
    {
      title:'',
      render: record => {
        if (record.estado === "RUNNING") {
          return(
            <Fragment>
              <div className='col-md-12 col-sm-12'>
                <div className='elem-list'>
                  <Button shape='circle' type='primary' 
                    title="Cambiar F. de Venc. del Correo" 
                    onClick={e=>handleOpenModal(record)}
                    icon={<CalendarOutlined />} />   
                </div>
              </div>
            </Fragment>
          )
        } else {
          if (record.estado !== "RUNNING") {
            return(
              <Fragment>
                <div className='col-md-12 col-sm-12'>
                  <div className='elem-list'>
                    <Button shape='circle' type='primary' 
                      title="Ver Transacciones" 
                      onClick={e => handleOpenModalTrs(record)}
                    icon={<EyeOutlined />} />
                  </div>
                </div>
              </Fragment>
            )
          }
        }
      }
    }
  ];

  const optionFilter = (input, option) =>
    option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const VentasListFiltradas = () => {
    const lista = ventasList.filter(venta => {
      if (selectEstado === undefined || selectEstado === '') {
        if (fechaInicio === "" || fechaInicio === undefined ||
          fechaFin === "" || fechaFin === undefined) {
            return venta
        } else {
          return moment(venta.fecha_venta_web).format("YYYY-MM-DD") >= fechaInicio && 
            moment(venta.fecha_venta_web).format("YYYY-MM-DD") <= fechaFin
        }
      } else {
        if (fechaInicio === "" || fechaInicio === undefined ||
          fechaFin === "" || fechaFin === undefined) {
            return venta.estado === selectEstado
        } else {
          return venta.estado === selectEstado &&
            moment(venta.fecha_venta_web).format("YYYY-MM-DD") >= fechaInicio && 
            moment(venta.fecha_venta_web).format("YYYY-MM-DD") <= fechaFin
        }
      }
    })
    return lista
  }

  const ExportarPDF = () => {
    componentePDF.current.save()
  }

  if (ventasList.length > 0) {
    return (
      <>
      {PdfFileOrdenPago(componentePDF, VentasListFiltradas(), fechaInicio, fechaFin, selectEstado)}
        <div className='row'>
          {/* <div>
          <iframe src="http://10.10.40.221/horizontal/pago/0002276443"></iframe>
          </div> */}
          <div className='col-md-4 col-lg-4'>
            <div className='elem-list'>
              <label>Buscar:</label>  
              <Select
                allowClear
                defaultValue={selectEstado}
                className='mb-lg-0 mb-4'
                placeholder='Seleccionar Estado'
                optionFilterProp='children'
                filterOption={optionFilter}
                onChange={e => setSelectEstado(e)}
              >
                <Option value='PAID'>
                  <Tag color={'#b7ce63'}>PAGADO</Tag>
                </Option>
                <Option value='RUNNING'>
                  <Tag color={'#336cfb'}>EN CURSO</Tag>
                </Option>
                <Option value='REFUSED'>
                  <Tag color={'#5d5a28 '}>RECHAZADO</Tag>
                </Option>
                <Option value='CANCELED'>
                  <Tag color={'#ed5564'}>CANCELADO</Tag>
                </Option>
                <Option value='EXPIRED'>
                  <Tag>EXPIRADO</Tag>
                </Option>
              </Select>
            </div>
          </div>

          <div className='col-md-4 col-lg-4'>
            <label>Desde: </label> <br/> 
            <Input type="date"
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-4 col-lg-4'>
            <label>Hasta: </label> <br/> 
            <Input type="date"
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>
        </div>

        <Card>
          <div className='elem-list mb-2'>
            <div>
              <Button danger 
                title="Exportar PDF" 
                onClick={e => ExportarPDF()}
                icon={<FilePdfOutlined />}>PDF
              </Button>
            </div>
            
            <div>
              {ExcelFileOrdenPago(VentasListFiltradas(), fechaInicio, fechaFin, selectEstado)}
            </div>
          </div>  
          <div>
            <Table
              pagination={{ pageSize: 10 }}
              columns={columns}
              dataSource={VentasListFiltradas()}
              size='small'
            />
          </div>

          {<ModalFechaSolicitud
            openModal = {openModal}
            handleCloseModal = {handleCloseModal}
            venta = {ventaSeleccionada}
            setVenta = {setVentaSeleccionada}
            Editar = {Editar}
            loadingButton = {loadingButton}
          />}

          {<ModalTrsPorOrden
            openModal = {openModalTrs}
            venta = {ventaSeleccionada}
            handleCloseModal = {handleCloseModalTrs}
            transaccionList = {transaccionList}
          />}
        </Card>
      </>   
    )    
  } else {
    return(
      <>
        <div className='row'>
          <div className='col-md-4 col-lg-4'>
            <div className='elem-list'>
              <label>Buscar:</label>  
              <Select
                allowClear
                defaultValue={selectEstado}
                className='mb-lg-0 mb-4'
                placeholder='Seleccionar Estado'
                optionFilterProp='children'
                filterOption={optionFilter}
                onChange={e => setSelectEstado(e)}
              >
                <Option value='PAID'>
                  <Tag color={'#b7ce63'}>PAGADO</Tag>
                </Option>
                <Option value='RUNNING'>
                  <Tag color={'#336cfb'}>EN CURSO</Tag>
                </Option>
                <Option value='REFUSED'>
                  <Tag color={'#5d5a28 '}>RECHAZADO</Tag>
                </Option>
                <Option value='CANCELED'>
                  <Tag color={'#ed5564'}>CANCELADO</Tag>
                </Option>
                <Option value='EXPIRED'>
                  <Tag>EXPIRADO</Tag>
                </Option>
              </Select>
            </div>
          </div>

          <div className='col-md-4 col-lg-4'>
            <label>Desde: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaInicio}
              onChange={e => setFechaInicio(e.currentTarget.value)}
            />
          </div>

          <div className='col-md-4 col-lg-4'>
            <label>Hasta: </label> <br/> 
            <Input type="date"/*"datetime-local"*/
              value={fechaFin} 
              onChange={e => setFechaFin(e.currentTarget.value)}
            />  
          </div>
        </div>

        <Card /*title='Solicitudes de Pago'*/>
          No hay Datos
        </Card>

      </>
    )
  }

}

export default SolicitudVenta
