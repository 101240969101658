import {Fragment, useEffect, useState} from "react";
import { IPageData } from "../../interfaces/page";
import { usePageData } from '../../hooks/usePage';
import KRGlue from "@lyracom/embedded-form-glue";
import { useParams } from "react-router-dom";
import { message } from "antd";
import Loading from "../../components/Loading";
import PublicLayout from "../../layout/public/Public";
import moment from "moment";

const pageData: IPageData = {
  fulFilled: true,
  title: 'Pago de Servicio',
  breadcrumbs: [
    // {
    //   title: 'Home',
    //   route: 'pago'
    // },
    {
      title: 'Pago de Servicio',
    }
  ]
};

const FormularioPago = () => {
  usePageData(pageData);
  const [loading, setLoading] = useState(true)
  const [factura, setFactura] = useState({
    ap_nom:'',
    medico:'',
    id_tipdoc: '',
    nume_doc:'',
    Parv_Descripcion:'',
    fecha_atencion:''
  })
  /**************EL ID_FACTU DE LA URL******************** */
  const {id_factu}: {id_factu: string} = useParams();

  useEffect(() => {
    getTicketById(id_factu)
  }, []);

  const getTicketById = async(id_factu) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getTicketById/${id_factu}`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.message) {
          message.error('El ticket Solicitado ya se ha Pagado')
          const interval = setInterval(() => { 
            window.location.href='https://limatambo.com.pe/'
          }, 3000);
          return () => clearInterval(interval);
        }else {
          setFactura(data)
          cargarFormulario(data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const cargarFormulario = async(ticket) =>{

    const order = {
      amount: ticket.Monto_Paciente*100,
      currency: "PEN",
      orderId: ticket.id_tipdoc+"-"+ticket.nume_doc,
      customer: {
          email: ticket.Email,
          phoneNumber: ticket.Telefono,
          cellPhoneNumber: ticket.celular
      }
    };

    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/CreatePayment`,{
        method: "POST",
        body: JSON.stringify(order),
        headers:{
          'Content-Type': 'application/json',//,para poder enviar un JSON
          'Access-Control-Allow-Origin': '*'
        }
      }).then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.status === 'SUCCESS') {
          KRGlue.loadLibrary('https://static.micuentaweb.pe',
          `${process.env.REACT_APP_PUBLIC_KEY}`)
          .then(({KR}) => {
            setLoading(false)
            KR.setFormToken(data.answer.formToken)
            KR.setFormConfig(({
              'kr-post-url-success': `https://pacientes.limatambo.com.pe/cita/CitaAutorizada/${id_factu}`
              // 'kr-post-url-refused': 'http://localhost:4000/pagado'
            }))
            KR.onSubmit(onPaid)
            KR.onError(onerror)
          })
        } else {
          message.error('ERROR: '+ data.answer.errorCode + ' ' + data.answer.errorMessage)
        }
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const onerror = async(event) => {
    const transacciones = event.metadata.answer.clientAnswer.transactions

    for (let i = 0; i < transacciones.length; i++) {
      const body = {...transacciones[i], id_factu: id_factu}
      try {
        await fetch(`${process.env.REACT_APP_HOSTNAME}/AddTransaccionFront`,{
          method: "POST",
          body: JSON.stringify(body),
          headers:{
            'Content-Type': 'application/json'
          }
        })  
      } catch (error) {
        console.log(error)
      }
    }
  }

  const onPaid = async(event) => {
    if (event.clientAnswer.orderStatus === "PAID") {
      // Remove the payment form
      //KR.removeForms();
      const transacciones = event.clientAnswer.transactions
      // console.log(transacciones)
      for (let i = 0; i < transacciones.length; i++) {
        const body = {...transacciones[i], id_factu: id_factu}
        try {
          await fetch(`${process.env.REACT_APP_HOSTNAME}/AddTransaccionFront`,{
            method: "POST",
            body: JSON.stringify(body),
            headers:{
              'Content-Type': 'application/json'
            }
          })  
        } catch (error) {
          console.log(error)
        }
      }
      // Show success message
      document.getElementById("paymentSuccessful").style.display = "block";
    } else {
      // Show error message to the user
      alert("Payment failed !");
    }
  }

  if (loading == true) {
    return(
      <PublicLayout bgImg={`${window.origin}/content/imgFormPasarela.jpeg`}>
        <Loading/>
      </PublicLayout>
    )
  } else {
    return(
      <PublicLayout bgImg={`${window.origin}/content/imgFormPasarela.jpeg`}>
        <label style={{fontSize:'20px', fontWeight:'bold', textDecorationLine: 'underline'}}>
          Confirmar el pago del Servicio: {factura.Parv_Descripcion}</label>
        <label style={{fontSize:'17px', fontWeight:'bold'}}>
          Orden N°: {factura.nume_doc} </label>
        <label style={{fontSize:'17px', fontWeight:'bold'}}>Paciente: {factura.ap_nom}</label>
        <label style={{fontSize:'17px', fontWeight:'bold'}}>Dr(a).: {factura.medico}</label>
        <label style={{fontSize:'17px', fontWeight:'bold'}}>
          Fecha de atención: {moment.utc(factura.fecha_atencion).format('DD/MM/YYYY')}
        </label>
        <br/>
        <div id="paymentForm" className="kr-embedded" /*kr-popin='true'*/ 
        /*kr-form-token="DEMO-TOKEN-TO-BE-REPLACED"*/>
          <div className="kr-pan"></div>
          <div className="kr-expiry"></div>
          <div className="kr-security-code"></div>
          <div className="kr-installment-number" style={{display: 'none'}}></div>
          <div className="kr-first-installment-delay" style={{display: 'none'}}></div>
          <button className="kr-payment-button"
            style={{width:"100%"/*, backgroundColor:"#009C0E"*/}}></button>
          <div className="kr-form-error"></div>
        </div>
      </PublicLayout>
    )
  }
  
}

export default FormularioPago