import {Fragment, useEffect, useRef, useState} from "react";
import moment from "moment";
import { Card, Table, Button, Tag, 
  Input, Popconfirm, message } from 'antd';
import { IPageData } from "../../interfaces/page";
import { usePageData } from '../../hooks/usePage';
import Cookies from "universal-cookie";
import { useHistory } from 'react-router-dom';
import { PdfFileTransacciones } from "./GenerarPdf";
import { FilePdfOutlined } from "@ant-design/icons";
import { ExcelFileTrs } from "./GenerarExcel";

const cookies = new Cookies()

const pageData: IPageData = {
  fulFilled: true,
  title: 'Transacciones',
  breadcrumbs: [
    // {
    //   title: 'Home',
    //   route: 'transacciones'
    // },
    {
      title: 'Transacciones',
    }
  ]
};

const Transacciones = () => {
  usePageData(pageData);
  const history = useHistory();
  const componentePDF = useRef(null)

  const [transaccionesWebList, setTransaccionesWebList] = useState([])
  const [fechaInicio, setFechaInicio] = useState(moment(new Date).format("YYYY-MM-DD"))
  const [fechaFin, setFechaFin] = useState(moment(new Date).format("YYYY-MM-DD"))

  useEffect(() => {
    
    // console.log(transaccionesWebList)
    // console.log(moment(new Date()).format("YYYY-MM-DD"))
    if (!cookies.get('nameid')) {
      history.push(`/public/sign-in`)
      // window.location.href='/public/sign-in'
    } else {
      getTransaccionesList() 
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getTransaccionesList()
    }, 10000);
    return () => clearInterval(interval);
  }, [transaccionesWebList])
  
  const getTransaccionesList = async() => {
    // console.log(new Date())
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/getTransaccionesList`)
      .then(res => res.json())
      .then(data => {
        // console.log(data)
        setTransaccionesWebList(data)
        // setLoading(false)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const Cancelar = async(transaccion) => {

    const body = {
      uuid: transaccion.uuid
    }
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/Transaction/CancelOrRefund`,{
        method: "POST",
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        // console.log(data)
        if (data.answer.errorCode) {
          message.error('ERROR: '+ data.answer.errorCode + ' ' + data.answer.errorMessage)
        }else {
          CancelarTransaccion({...body, 
            Id_transaccion: transaccion.Id_transaccion,
            estado: data.answer.status,
            detalle_estado: data.answer.detailedStatus
          })
        }
        
      })
    } catch (error) {
      console.log(error)
    }
  }

  const CancelarTransaccion = async(transaccion) => {
    try {
      await fetch(`${process.env.REACT_APP_HOSTNAME}/updateTransaccion`,{
        method: "PUT",
        body: JSON.stringify(transaccion),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
      .then(data => {
        // console.log(data)
      }).catch(err => console.log(err));
    } catch (error) {
      console.log(error)
    }
  }

  const TransaccionesFiltradas = () => {

    const lista = transaccionesWebList.filter(transaccion =>{
      return moment(transaccion.fecha_transaccion).format("YYYY-MM-DD") >= fechaInicio && 
        moment(transaccion.fecha_transaccion).format("YYYY-MM-DD") <= fechaFin
    })
    if (fechaInicio === "" || fechaInicio === undefined ||
      fechaFin === "" || fechaFin === undefined) {
      return transaccionesWebList
    } else {
      return lista
    }
  }

  const diffFecha = (fechaTransaccion) => {
    const hoy = new Date()
    const fechatr = new Date(moment(fechaTransaccion).format('YYYY-MM-DD'))

    var timeDiff = (hoy.getTime() - fechatr.getTime());
    var diffDays = (timeDiff / (1000 * 3600 * 24))

    // console.log(diffDays)
    return diffDays
  }

  const columns = [
    {
      title: 'SERVICIO',
      dataIndex: 'tipo_atencion',
      key: 'tipo_atencion'
    },
    {
      title: 'CÓDIGO',
      dataIndex: 'codigo',
      key: 'codigo'
    },
    {
      title: 'MONTO',
      dataIndex: 'monto',
      key: 'monto',
      render: text => (
        "S/."+text
      )
    },
    {
      title: 'PACIENTE',
      dataIndex: 'ap_nom',
      key: 'ap_nom'
    },
    {
      title: 'ESTADO SOLICITUD',
      dataIndex: '',
      key: '',
      render: record => {
        switch (record.estado) {
          case "PAID":
            switch (record.detalle_estado) {
              case "ACCEPTED":
                return(<Tag color={'#b7ce63'}>PAGADO - ACEPTADO</Tag>);
              
              case "AUTHORISED":
                return(<Tag color={'#b7ce63'}>PAGADO - AUTORIZADO</Tag>);
            
              case "CAPTURED":
                return(<Tag color={'#b7ce63'}>PAGADO - CAPTURADO</Tag>);
              
              case "PARTIALLY_AUTHORISED":
                return(<Tag color={'#b7ce63'}>PAGADO - PARCIALMENTE AUTORIZADO</Tag>);
            
              default:
              break;
            }
          break;

          case "RUNNING":
            switch (record.detalle_estado) {
              case "AUTHORISED_TO_VALIDATE":
                return(<Tag color={'#336cfb'}>EN CURSO - AUTORIZADO PARA VALIDAR</Tag>);

              case "WAITING_AUTHORISATION":
                return(<Tag color={'#336cfb'}>EN CURSO - ESPERANDO AUTORIZACIÓN</Tag>);
                
              case "WAITING_AUTHORISATION_TO_VALIDATE":
                return(<Tag color={'#336cfb'}>EN CURSO - ESPERANDO AUTORIZADO PARA VALIDAR</Tag>);
                  
              default:
              break;
            }
          break;  

          case "UNPAID":
            switch (record.detalle_estado) {
              case "REFUSED":
                return(<Tag color={'#ed5564'}>NO PAGADO - RECHAZADO</Tag>);
              
              case "ERROR":
                return(<Tag color={'#ed5564'}>NO PAGADO - {record.detalle_estado}</Tag>);
                  
              case "CANCELLED":
                return(<Tag color={'#ed5564'}>NO PAGADO - CANCELADO</Tag>);
                  
              case "EXPIRED":
                return(<Tag color={'#ed5564'}>NO PAGADO - EXPIRADO</Tag>);

              default:
                break;
            }
          break;

          default:
          break;
        }
      }
    },
    {
      title: 'F. TRANSACCION',
      dataIndex: 'fecha_transaccion',
      key: 'fecha_transaccion',
      render: text => (
        moment.utc(text).format('DD/MM/YY HH:mm')
      )
    },
    {
      title:'ORIGEN',
      dataIndex: 'origen',
      key: 'origen',
      // render: record => {
      //   if (record.detalle_estado === "AUTHORISED_TO_VALIDATE" ||
      //     record.detalle_estado === "WAITING_AUTHORISATION_TO_VALIDATE" ||
      //     record.detalle_estado === "WAITING_AUTHORISATION" ||
      //     record.detalle_estado === "AUTHORISED") {
      //     return(
      //       <Fragment>
      //         <div className='col-md-12 col-sm-12'>
      //           <div className='elem-list'>
      //           <Popconfirm okText="Si" cancelText="No"
      //             title = "¿Cambiar Fecha de Vigencia?"
      //             onConfirm={e=>Cancelar(record)}
      //           >
      //             <Button danger 
      //               //onClick={e=>Cancelar(record)}
      //             >Cancelar</Button> 
      //           </Popconfirm>
                    
      //           </div>
      //         </div>
      //       </Fragment>
      //     )
      //   } else {
      //     if (record.detalle_estado === "CAPTURED" && 
      //     diffFecha(record.fecha_captura) <=1) {
      //       return (
      //         <Fragment>
      //           <div className='col-md-12 col-sm-12'>
      //             <div className='elem-list'>
      //               <Popconfirm okText="Si" cancelText="No"
      //                 title = "¿Cambiar Fecha de Vigencia?"
      //                 onConfirm={e=>Cancelar(record)}
      //               >
      //                 <Button danger 
      //                   //onClick={e=>Cancelar(record)}
      //                 >Reembolsar</Button> 
      //               </Popconfirm> 
      //             </div>
      //           </div>
      //         </Fragment>
      //       )
      //     }
      //   }
      // },
      width: 150
    }
  ];

  const ExportarPDF = () => {
    componentePDF.current.save()
  }

  if (transaccionesWebList.length > 0) {
    return(
      <>
        {PdfFileTransacciones(componentePDF, TransaccionesFiltradas(), fechaInicio, fechaFin)}
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='elem-list'>
              <label>Desde: </label> 
              <Input type="date"/*"datetime-local"*/
                value={fechaInicio}
                onChange={e => setFechaInicio(e.currentTarget.value)}
              />
              <label>Hasta: </label> 
              <Input type="date"/*"datetime-local"*/
                value={fechaFin} 
                onChange={e => setFechaFin(e.currentTarget.value)}
              />   
            </div>
          </div>  

          <div className='col-md-6 col-sm-12'>
            <Button danger className="mr-2"
                title="Exportar PDF" 
                onClick={e => ExportarPDF()}
                icon={<FilePdfOutlined />} 
            >PDF
            </Button>
            {ExcelFileTrs(TransaccionesFiltradas(), fechaInicio, fechaFin)}
          </div>
        </div>

        <Card>
          <div>
            <Table
              pagination={{ pageSize: 10 }}
              columns={columns}
              dataSource={TransaccionesFiltradas()}
              size='small'
            />
          </div>
        </Card>
      </>
    )  
  } else {
    return(
      <>
        <div className='row'>
          <div className='col-md-6 col-sm-12'>
            <div className='elem-list'>
              <label>Desde: </label> 
              <Input type="date"/*"datetime-local"*/
                value={fechaInicio}
                onChange={e => setFechaInicio(e.currentTarget.value)}
              />
              <label>Hasta: </label> 
              <Input type="date"/*"datetime-local"*/
                value={fechaFin} 
                onChange={e => setFechaFin(e.currentTarget.value)}
              />   
            </div>
          </div>  
        </div>

        <Card /*title='Solicitudes de Pago'*/>
          No hay Datos
        </Card>
      </>
    )
  }
  
  
  
}

export default Transacciones